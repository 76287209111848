import React, { useEffect, useState } from "react";
import Index from "../Index";
import PagesIndex from "../PagesIndex";
import { Grid } from "@mui/material";
import "./BusinessList.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const BusinessView = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const [data, setData] = useState([]);
  const viewData = PagesIndex.useLocation();
  const getdata = viewData.state;
  const [pageValue, setPageValue] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState([]);

  const [transactionData, setTransactionData] = useState();
  const [loading, setLoading] = useState(false);
  const allTransaction = async () => {
    try {
      setLoading(true);
      const res = await PagesIndex.DataService.get(
        `${PagesIndex.Api.Admin.Get_AllTransaction}?businessId=${getdata._id}`);
      setTransactionData(res?.data?.data
        );
      setLoading(false);
    } catch (error) {}
  };

  const pageChange = (e, value) => {
      setPageValue(value);
    };
  
    const handleSearchChange = (e) => {
      const searchQuery = e.target.value;
      const result = transactionData?.filter((item) => {
        const status = item?.status === "success" ? "completed" : "pending";
        const amount = item?.amount.toString();
        const transactionId = item?.txn_id.toString();
        return (
          (status && status.toUpperCase().includes(searchQuery)) ||
          status.toLowerCase().includes(searchQuery) ||
          amount.includes(searchQuery) ||
          transactionId.toLowerCase().includes(searchQuery) ||
          transactionId.toUpperCase().includes(searchQuery)
        );
      });
  
      setSearchTerm(result);
      setPageValue(1);
    };

 
    const invoiceDownload = (res) => {
      setData(res);
      setOpenDelete(true);
    };

  
    useEffect(() => {
      allTransaction();
    }, []);
  
    useEffect(() => {
      setSearchTerm(transactionData);
    }, [transactionData]);
  
    const rowsPerPage = 5;
    useEffect(() => {
      const countData = Math.ceil(searchTerm?.length / rowsPerPage);
      setPageCount(countData);
      const indexOfLastRow = pageValue * rowsPerPage;
      const indexOfFirstRow = indexOfLastRow - rowsPerPage;
      const modifiedRows =
        searchTerm && searchTerm?.slice(indexOfFirstRow, indexOfLastRow);
  
      setPageData(modifiedRows);
      if (modifiedRows?.length === 0 && pageValue > 1) {
        setPageValue(pageValue - 1);
      }
    }, [pageValue, searchTerm]);

    const downloadPdf = () => {
      const capture = document.querySelector(".invoice");
      html2canvas(capture, { scale: 1.5 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const doc = new jsPDF("p", "mm", "a4");
        const componentWidth = doc.internal.pageSize.getWidth();
        const componentHeight = doc.internal.pageSize.getHeight();
        doc.addImage(imgData, "PNG", 0, 0, componentWidth, componentHeight);
        doc.save("ugoInvoice.pdf");
      });
    };
  
  
  return (
    <>
      <Index.Box className="dashboard-content  ">
        <Index.Box className="user-list-flex">
          <Index.Box>
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.Typography
                  className="admin-page-title user-list-page-title"
                  component="h2"
                  variant="h2"
                >
                  Business view
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="flex-all user-list-inner-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                <PagesIndex.Link to="/admin/business-list">
                  <Index.Button className="adduser-btn btn-primary">
                    <Index.ArrowBackIcon className="plus-icon" />
                    Back
                  </Index.Button>
                </PagesIndex.Link>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-dashboard-list-row view-box">
          <Grid container>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Full Name
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata.fullName}
                />
              </Index.Box>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Contact Number
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field"
                  value={getdata.number}
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Email
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  value={getdata.email}
                  className="form-control input-field "
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Company Name
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata.companyName}
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Job Title
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata.jobTitle}
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Opening Time
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata.openingTime}
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Closing Time
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata.closingTime}
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Business Category
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata?.category?.name}
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Business SubCategory
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata?.subCategory?.name}
                />
              </Index.Box>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Post Code
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata?.postalCode}
                />
              </Index.Box>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Address Line One
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata?.address?.addressLineOne}
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Address Line Two
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata?.address?.addressLineTwo}
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Town
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata?.town}
                />
              </Index.Box>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <Index.Typography
                variant="label"
                component="label"
                className="change-input-label "
              >
                Country
              </Index.Typography>
              <Index.Box className="input-box">
                <Index.TextField
                  className="form-control input-field "
                  value={getdata?.country}
                />
              </Index.Box>
            </Grid>
          </Grid>
        </Index.Box>

        <Index.Box className="admin-dashboard-list-row ">
          {/* <Index.Box className="h-100"> */}
          {/* <Index.Box className="card-center"> */}
          <Index.Box className="txn-table-box">
            
            <Index.Box className="title-main change-password-set">
              <Index.Box className="userlist-btn-flex">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Transactions
                </Index.Typography>
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      onChange={handleSearchChange}
                      id="fullWidth"
                      autoComplete="off"
                      className="form-control "
                      placeholder="Search Transaction"
                    />

                    <img
                      src={PagesIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="">
              <Index.Box className="table-main-div">
                <Index.TableContainer component={Index.Paper}>
                  <Index.Table
                    aria-label="simple table"
                    className="table-main user-table users-table"
                  >
                    {/* {loading ? (
                        <PagesIndex.UgoLoader
                          color="#233862"
                          loading={loading}
                        />
                      ) : ( */}
                    <>
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>No.</Index.TableCell>
                          <Index.TableCell>Invoice number</Index.TableCell>
                          <Index.TableCell align="center">Date</Index.TableCell>
                          <Index.TableCell align="left">
                            Payment Methord
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            Invoice Amount{" "}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            Paid status{" "}
                          </Index.TableCell>
                          <Index.TableCell align="left"></Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>

                      <Index.TableBody>
                            {searchTerm && searchTerm?.length > 0 ? (
                              <>
                                {pageData &&
                                  pageData.map((res, index) => (
                                    <Index.TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell align="left">
                                        {(pageValue - 1) * rowsPerPage +
                                          index +
                                          1}
                                      </Index.TableCell>
                                      <Index.TableCell align="left">
                                        {res?.txn_id}
                                      </Index.TableCell>
                                      <Index.TableCell align="left">
                                      {res?.createdAt
                                    ? new Date(
                                        res.createdAt
                                      ).toLocaleDateString("en-GB")
                                    : ""}
                               </Index.TableCell>
                                      <Index.TableCell align="left">
                                        Card Payment
                                      </Index.TableCell>
                                      <Index.TableCell align="left">
                                      &#8356;{parseFloat(res?.amount).toFixed(2)}
                                      </Index.TableCell>
                                      <Index.TableCell align="left">
                                      {res?.status === "success"
                                          ? "Completed"
                                          : res?.status === "pending"
                                          ? "Pending"
                                          : "-"}
                                      </Index.TableCell>
                                      <Index.TableCell align="left">
                                        <Index.Box className="set-img-transaction transaction">
                                          <Index.Button
                                            variant="contained"
                                            onClick={()=>invoiceDownload(res)}
                                          >
                                            <img
                                              src={PagesIndex.Svg.completed}
                                              alt="logo"
                                              className=""
                                            />
                                          </Index.Button>
                                        </Index.Box>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  ))}
                              </>
                            ) : (
                              <>
                                <Index.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    colSpan={6}
                                    sx={{ textAlign: "center" }}
                                  >
                                    {" "}
                                    No Transactions Available
                                  </Index.TableCell>
                                </Index.TableRow>
                              </>
                            )}
                          </Index.TableBody>
                    </>
                    {/* )} */}
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>

              {searchTerm && searchTerm?.length > 0 ? (
                  <Index.Box className="pagination-main">
                    <Index.Pagination
                      count={pageCount}
                      page={pageValue}
                      onChange={pageChange}
                      variant="outlined"
                      shape="rounded"
                      className="pagination"
                    />
                  </Index.Box>
                ) : null}
            </Index.Box>
            {/* </Index.Box> */}
          </Index.Box>
          {/* </Index.Box> */}
        </Index.Box>
      </Index.Box>
      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        data={data}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box className="delete-modal-inner-main-box modal-inner">
          <button onClick={downloadPdf} className="pdfbutton">
            {" "}
            <img src={PagesIndex.Svg.completed} alt="logo" className="" />
          </button>
          <div className="invoice">
            <div className="invoice-header">
              <h3>UGO OUT Invoice</h3>
              <div className="header-contain-invoice">
                <Index.Box>
            
                  <p>Invoice : {data?.txn_id}</p>
                  <p>Date :  {data?.createdAt
                                    ? new Date(
                                      data.createdAt
                                      ).toLocaleDateString("en-GB")
                                    : ""}
                  </p>
                </Index.Box>
                <Index.Box className="logo-box">
                  <img src={PagesIndex.Svg.uplogo} className="invoice-logo" />
                </Index.Box>
              </div>
            </div>
            <div className="invoice-details">
              <h4>Customer Details :</h4>
              <div className="sender-details">
                <p>Customer : {data?.product?.businessId?.fullName}</p>
                <p>City : {data?.product?.businessId?.town}</p>
                <p>Email : {data?.product?.businessId?.email}</p>
       
              </div>
              <div className="recipient-details">
              <p> Contact Number : {data?.product?.businessId?.number}</p>
                <p> Postal Code : {data?.product?.businessId?.postalCode}</p>
                <p> Transaction Status : { data?.status === "success" ? "Completed" : "Pending"} </p>
                <p></p>
              </div>
            </div>
            <table className="invoice-table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td>{data?.product?.name}</td>
                  <td>{data?.couponQuntity}</td>
                  <td>&pound;{data?.couponAmount}</td>
                  <td>&pound;{data?.couponQuntity*data?.couponAmount}</td>
                </tr>
                {/* <tr>
                  <td>Item 2</td>
                  <td>3</td>
                  <td>$30.00</td>
                  <td>$90.00</td>
                </tr> */}
                {/* Add more rows for additional items */}
              </tbody>
              <tfoot>
              <tr>
                  <td colSpan="3">Subtotal</td>
                  <td>&pound;{data?.couponQuntity*data?.couponAmount}</td>
                </tr>
                <tr>
                  <td colSpan="3">Tax ({data?.vatAmount}%)</td>
                  <td>&pound;{(data?.couponQuntity*data?.couponAmount)*(data?.vatAmount)/100}</td>
                </tr>
                <tr>
                  <td colSpan="3">Total</td>
                  <td>&pound;{parseFloat(data?.amount).toFixed(2)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default BusinessView;