import React, { useEffect, useState } from "react";
import Index from "../Index";
import PagesIndex from "../PagesIndex";
import './DistanceCost.css'

const DistanceCost = () => {
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [id, setId] = useState("");
  const [initialValues, setInitialValues] = useState({
    distanceCost: [
      {
        distance: "",
        price: "",
      },
    ],
  });

  const submitForm = async (values) => {
    try {
      setBtnLoading(true);
      const data = {
        distanceArray: values.distanceCost,
      };

      if (id) {
        data.id = id;
      }
      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.Add_Update_Distance_Cost,
        data
      );
      if (res?.data.status === 200) {
        PagesIndex.toast.success(res?.data?.message);
        getAllDistanceCost();
      }
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);
    }
  };

  const getAllDistanceCost = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Admin.getAllDistanceCost
      );
      if (res?.data.status === 200) {
        setInitialValues({ distanceCost: res.data.data[0].distanceCostArray });
        setId(res.data.data[0]._id);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllDistanceCost();
  }, []);
  return (
    <>
      <Index.Box className="dashboard-content ">
        <Index.Box className="user-list-flex">
          <Index.Box>
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.Typography
                  className="admin-page-title user-list-page-title"
                  component="h2"
                  variant="h2"
                >
                  Distance Cost
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        {!loading ? (
          <PagesIndex.Formik
            validationSchema={PagesIndex.DistanceCostSchema}
            initialValues={initialValues}
            onSubmit={submitForm}
            enableReinitialize
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="common-card">
                  <PagesIndex.FieldArray name="distanceCost">
                    {({ insert, remove, push }) => (
                      <>
                        {values.distanceCost.length > 0 &&
                          values.distanceCost.map((friend, index) => (
                            <Index.Box className="distance-row">
                              <Index.Box className="admin-input-box ">
                                <Index.Box className="admin-form-group">
                                  <Index.TextField
                                    className="admin-form-control"
                                    placeholder="Distance in KM"
                                    name={`distanceCost.${index}.distance`}
                                    value={values.distanceCost[index].distance}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      if (
                                        value === "" ||
                                        /^[0-9]+$/.test(value)
                                      ) {
                                        setFieldValue(
                                          `distanceCost.${index}.distance`,
                                          value
                                        );
                                      }
                                    }}
                                    error={Boolean(
                                      PagesIndex.getIn(
                                        errors,
                                        `distanceCost[${index}].distance`
                                      )
                                    )}
                                    helperText={
                                      PagesIndex.getIn(
                                        errors,
                                        `distanceCost[${index}].distance`
                                      ) || ""
                                    }
                                  />
                                </Index.Box>
                              </Index.Box>
                              {/* {errors.distanceCost[index].distance &&
                              errors.distanceCost[index].distance ? (
                                <Index.Typography className="error-msg">
                                  {errors.distanceCost[index].distance}
                                </Index.Typography>
                              ) : null} */}
                              <Index.Box className="admin-input-box ">
                                <Index.Box className="admin-form-group">
                                  <Index.TextField
                                    className="admin-form-control"
                                    placeholder="Price"
                                    name={`distanceCost.${index}.price`}
                                    value={values.distanceCost[index].price}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      if (
                                        value === "" ||
                                        /^[0-9]+$/.test(value)
                                      ) {
                                        setFieldValue(
                                          `distanceCost.${index}.price`,
                                          value
                                        );
                                      }
                                    }}
                                    error={Boolean(
                                      PagesIndex.getIn(
                                        errors,
                                        `distanceCost[${index}].price`
                                      )
                                    )}
                                    helperText={
                                      PagesIndex.getIn(
                                        errors,
                                        `distanceCost[${index}].price`
                                      ) || ""
                                    }
                                  />
                                </Index.Box>
                              </Index.Box>
                              {/* {errors.distanceCost[index].Price &&
                              errors.distanceCost[index].Price ? (
                                <Index.Typography className="error-msg">
                                  {errors.distanceCost[index].Price}
                                </Index.Typography>
                              ) : null} */}
                              <Index.Box  className="common-btn distance-btn-main">
                              {index === 0 ? (
                                <Index.Button
                                className="distance-btn"
                                  onClick={() =>
                                    push({ distance: "", price: "" })
                                  }
                                >
                                  +
                                </Index.Button>
                              ) : (
                                <Index.Button onClick={() => remove(index)}  className="distance-btn">
                                  -
                                </Index.Button>
                              )}
                              </Index.Box>
                            </Index.Box>
                          ))}
                      </>
                    )}
                  </PagesIndex.FieldArray>

                  <Index.Box className="user-main-btn-flex common-btn">
                    <Index.Button
                    className=""
                      variant="contained"
                      type="submit"
                      disabled={btnLoading}
                      style={{ color: "white" }}
                      startIcon={
                        btnLoading ? <PagesIndex.UgoButtonLoader /> : null
                      }
                    >
                      Save
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        ) : null}
      </Index.Box>
    </>
  );
};

export default DistanceCost;
