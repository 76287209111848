import React, { useEffect, useState } from "react";
import Index from "../Index";
import PagesIndex from "../PagesIndex";
import AddCategory from "./AddCategory";
import "./Category.css";
import "./Category.responsive.css";
import blankImage from "../../../../assets/images/png/blank-imge.png"

const Category = () => {
  const [pageValue, setPageValue] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [categoryData, setCategoryData] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = PagesIndex.useNavigate();

  const handleCategoryList = async (event) => {
    try {
      setLoading(true);
      const res = await PagesIndex.DataService.get(PagesIndex.Api.Common.Get_ListAllCategory)
      if (res?.data.status === 200) {
        setCategoryData(res?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      PagesIndex.toast.error(error);
    }
  };

  useEffect(() => {
    handleCategoryList();
  }, []);

  useEffect(() => {
    setSearchTerm(categoryData);
  }, [categoryData]);

  const handleSearchChange = (e) => {
    const searchQuery = e.target.value;
    const result =
      categoryData &&
      categoryData?.filter((item) => {
        return (
          new Date(item.createdAt)
            .toLocaleDateString("en-GB")
            .includes(searchQuery) ||
          (item?.name &&
            item?.name.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      });
    setSearchTerm(result);
    setPageValue(1);
  };

  const pageChange = (e, value) => {
    setPageValue(value);
  };

  const HandleActiveDeactiveCategory = async (categoryId, status) => {
    try {
      const urlEncodedData = new URLSearchParams();

      urlEncodedData.append("id", categoryId);
      urlEncodedData.append("status", status);

      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.ActiveInactive_CategoryStatus,
        urlEncodedData
      );

      if (res.data.status === 200) {
        PagesIndex.toast.success(res.data.message);
      }
      handleCategoryList();
    } catch (error) {
      PagesIndex.toast.error(error);
      
    }
  };

  const rowsPerPage = 10;
  useEffect(() => {
    const countData = Math.ceil(searchTerm?.length / rowsPerPage);
    setPageCount(countData);
    const indexOfLastRow = pageValue * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const modifiedRows =
      searchTerm && searchTerm?.slice(indexOfFirstRow, indexOfLastRow);

    setPageData(modifiedRows);
    if (modifiedRows?.length === 0 && pageValue > 1) {
      setPageValue(pageValue - 1);
    }
  }, [pageValue, searchTerm]);

  return (
    <>
      <Index.Box className="dashboard-content ">

        <Index.Box className="user-list-flex">

          <Index.Box>

            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.Typography
                  className="admin-page-title user-list-page-title"
                  component="h2"
                  variant="h2"
                >
                  Category List
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  onChange={handleSearchChange}
                  id="fullWidth"
                  autoComplete="off"
                  className="form-control"
                  placeholder="Search Category"
                />

                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>

            <Index.Box className="flex-all user-list-inner-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button className="adduser-btn btn-primary">
                  <img
                    src={PagesIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  <AddCategory handleCategoryList={handleCategoryList} />
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        
        <Index.Box className="admin-dashboard-list-row">
          {/* {loading ? (
            <PagesIndex.UgoLoader color="#233862" loading={loading} />
          ) : ( */}
            <Index.Box sx={{ width: 1 }} className="grid-main"> 
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box
                      sx={{ width: "100%", overflow: "hidden" }}
                      className="category-table-main page-table-main"
                    >
                      <Index.TableContainer
                        sx={{ maxHeight: "350px" }}
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                No.
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Image
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Category Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Created Date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
 
                          <Index.TableBody className="table-body">
                          {loading ? (
                            <>
                              {/* <Index.TableRow>
                                <Index.TableCell
                                  colSpan={10}
                                  align="center"
                                  className="no-data-cell"
                                >
                                  <PagesIndex.UgoLoader
                                    color="#233862"
                                    loading={loading}
                                  />
                                </Index.TableCell>
                              </Index.TableRow> */}
                            </>
                          ):(<>
                            {searchTerm?.length > 0 ? (
                              pageData &&
                              pageData.map((row, ind) => (
                                <Index.TableRow
                                  key={row?._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {(pageValue - 1) * rowsPerPage + ind + 1}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    
                                  
                                  >
                                    <Index.Box className="userlist-data">
                                      <img
                                        alt="img"
                                        src={row?.image ? `${PagesIndex.imageURL}${row.image}` : `${blankImage}`}
                                        // src={`${PagesIndex.imageURL}${row?.image}`}
                                        className="categorylist-img"
                                      ></img>{" "}
                                    </Index.Box>
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.name}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {new Date(row?.createdAt).toLocaleDateString(
                                      "en-GB"
                                    )}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <Index.Box className="userdata-btn-flex">
                                      <Index.Button
                                        className="action-btn"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          navigate("/admin/subcategory", {
                                            state: { row },
                                          });
                                        }}
                                      >
                                        <Index.AddIcon size="small" />
                                      </Index.Button>
                                      <AddCategory
                                        data={row}
                                        handleCategoryList={handleCategoryList}
                                      />
                                      <Index.Switch
                                        checked={row?.isActive === true}
                                        onClick={() =>
                                          HandleActiveDeactiveCategory(
                                            row?._id,
                                            row?.isActive === true ? false : true
                                          )
                                        }
                                      />
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <Index.TableRow>
                                <Index.TableCell
                                  colSpan={10}
                                  align="center"
                                  className="no-data-cell"
                                >
                                  No Category found....
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                            </>)}
                          </Index.TableBody>
         
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                    {searchTerm && searchTerm?.length > 0 ? (
                      <Index.Box className="pagination-main">
                        <Index.Pagination
                          count={pageCount}
                          page={pageValue}
                          onChange={pageChange}
                          variant="outlined"
                          shape="rounded"
                          className="pagination"
                        />
                      </Index.Box>
                    ) : null}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          {/* )} */}
        </Index.Box>
        
      </Index.Box>
    </>
  );
};

export default Category;
