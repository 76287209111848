import React from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import UgoPageNotFound from "../component/common/UgoPageNotFound";
import { createBrowserHistory } from "history";
import UgoLoader from "../component/common/UgoLoader";
import { ToastContainer } from "react-toastify";
import UsePrivateRoutes from "./UsePrivateRoutes";
import Login from "../container/auth/admin/login/Login";
import ForgotPassword from "../container/auth/admin/forgotPassword/ForgotPassword";
import ResetPassword from "../container/auth/admin/resetPassword/ResetPassword";
import OtpVerification from "../container/auth/admin/otpVerification/OtpVerification";
import Dashboard from "../container/pages/admin/dashboard/Dashboard";
import Category from "../container/pages/admin/category/Category";
import SubCategory from "../container/pages/admin/subCategory/SubCategory";
import ChangePassword from "../container/auth/admin/changePassword/ChangePassword";
import AdminProfile from "../container/pages/admin/adminProfile/AdminProfile";
import PrivacyPolicy from "../container/pages/admin/privcyPolicy/PrivacyPolicy";
import TermsCondition from "../container/pages/admin/termsCondition/TermsCondition";
import UserList from "../container/pages/admin/user/UserList";
import BusinessList from "../container/pages/admin/business/BusinessList";
import Home from "../container/pages/admin/Home/Home";
import SubscriptionPlan from "../container/pages/admin/subscriptionPlan/SubscriptionPlan";
import BusinessView from "../container/pages/admin/business/BusinessView";
import Transaction from "../container/pages/admin/transaction/Transaction";
import DistanceCost from "../container/pages/admin/distanceCost/DistanceCost";


const history = createBrowserHistory();

const Routes = () => {
  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="*" element={<UgoPageNotFound />} />
        <Route path="/" element={<Login />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="resetpassword" element={<ResetPassword />} />
        <Route path="otpverification" element={<OtpVerification />} />
        <Route path="/loader" element={<UgoLoader />} />

        <Route element={<UsePrivateRoutes />}>
          <Route path="/admin" element={<Dashboard />}>
          <Route path="dashboard" element={<Home />} />
          <Route path="subscription" element={<SubscriptionPlan/>} />
          <Route path="business-view" element={<BusinessView/>} />
            <Route path="category" element={<Category />} />
            <Route path="view-transaction" element={<Transaction />} />
            <Route path="subcategory" element={<SubCategory />} />
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="user-list" element={<UserList />} />
            <Route path="business-list" element={<BusinessList />} />
            <Route path="terms-condition" element={<TermsCondition />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="distance-cost" element={<DistanceCost/>} />
          
          </Route>
        </Route>
      </Routess>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Routes;
